import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const ExamSetup = Loadable(lazy(() => import('views/create-exams/exam-setup/ExamSetUp')));
const ExamTimeSetup = Loadable(lazy(() => import('views/create-exams/exam-setup/ExamTimeSetup')));
const ExamTimeSetupList = Loadable(lazy(() => import('views/create-exams/exam-setup/ExamTimeSetupList')));

const ExamAttendanceSetup = Loadable(lazy(() => import('views/create-exams/Attendance/index')));
const InternalAssessmentMarksSetup = Loadable(lazy(() => import('views/create-exams/Internal-Assessment/index')));
const ExamTimeSetupEdit = Loadable(lazy(() => import('views/create-exams/exam-setup/EditExamTimeSetup')));
const ExamInternalMarksEntry = Loadable(lazy(() => import('views/create-exams/exam_marks_entry/index')));
const MarksEntrySetup = Loadable(lazy(() => import('views/create-exams/Marks-Entry/index')));
const ExamTimeTable = Loadable(lazy(() => import('views/create-exams/examTimeTable/index')));
const MarksApproval = Loadable(lazy(() => import('views/create-exams/Marks-Approval/index')));
const MarkSheet = Loadable(lazy(() => import('views/markSheet/MarkSheet')));
const SemesterGradeSheet = Loadable(lazy(() => import('views/markSheet/semestergrade/index')));
const ExamtimeTableMangements = Loadable(lazy(() => import('views/examtimeTableSetup/index')));
const ExamtimeTableMangementsAdd = Loadable(lazy(() => import('views/examtimeTableSetup/AddbyCourseName')));
const ExamtimeTableMangementsEdit = Loadable(lazy(() => import('views/examtimeTableSetup/EditExamTimeTable')));
const BacklogPaperEntry = Loadable(lazy(() => import('views/create-exams/backlogentry/AdminSideBacklogEntry')));
const BacklogStudents = Loadable(lazy(() => import('views/create-exams/backlogentry/BacklogStudents')));
const GenerateHallTicket = Loadable(lazy(() => import('views/generate_hallticket/GenerateHallTicket')));
const OverAllAcedemicReort = Loadable(lazy(() => import('views/create-exams/over_all_academic_report/AcademicReport')));
const AcademicStates = Loadable(lazy(() => import('views/academicStates/index')));
const DepartmentAcademicStates = Loadable(lazy(() => import('views/academicStates/DepartmentAcademicStates')));
const CourseAcademicStates = Loadable(lazy(() => import('views/academicStates/CourseAcademicStates')));
const QuestionBank = Loadable(lazy(() => import('views/questionBank/index')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const ExamRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/upload-questionpaper',
            element: <QuestionBank />
        },
        {
            path: '/exam/setup',
            element: <ExamSetup />
        },
        {
            path: '/exam/time/setup/:id/:examType',
            element: <ExamTimeSetup />
        },
        {
            path: '/exam/time/setup/list/:id/:type',
            element: <ExamTimeSetupList />
        },
        {
            path: '/exam/attendence',
            element: <ExamAttendanceSetup />
        },
        {
            path: '/exam/time/setup/edit/:id',
            element: <ExamTimeSetupEdit />
        },
        {
            path: '/exam/marks/internal-assessment',
            element: <InternalAssessmentMarksSetup />
        },
        {
            path: '/exam/internal/marks/entry',
            element: <ExamInternalMarksEntry />
        },
        {
            path: '/exam/marks/add',
            element: <MarksEntrySetup />
        },
        {
            path: '/exam/timetable',
            element: <ExamTimeTable />
        },
        {
            path: '/exam/marks/approval',
            element: <MarksApproval />
        },
        {
            path: '/exam/marksheet',
            element: <MarkSheet />
        },
        {
            path: '/exam/semestergradesheets',
            element: <SemesterGradeSheet />
        },
        {
            path: '/exam/timetable/setup/:examGroupId',
            element: <ExamtimeTableMangements />
        },
        {
            path: '/exam/timetable/setup/Add/:examGroupId',
            element: <ExamtimeTableMangementsAdd />
        },
        {
            path: '/exam/timetable/setup/edit/:examTimeTableId',
            element: <ExamtimeTableMangementsEdit />
        },
        {
            path: '/backlog/students',
            element: <BacklogStudents />
        },
        {
            path: '/backlog/paper',
            element: <BacklogPaperEntry />
        },
        {
            path: '/generate/hallticket',
            element: <GenerateHallTicket />
        },
        {
            path: '/overall/academic/report',
            element: <OverAllAcedemicReort />
        },
        {
            path: '/exam/performance',
            element: <AcademicStates />
        },
        {
            path: '/exam/department/academicstates/:schoolId/:sessionId?',
            element: <DepartmentAcademicStates />
        },
        {
            path: '/exam/course/academicstates/:departmentId/:sessionId?',
            element: <CourseAcademicStates />
        }
    ]
};
export default ExamRoutes;
