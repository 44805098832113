import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const AnouncementList = Loadable(lazy(() => import('views/application/anouncements/index')));
const AnouncementEdit = Loadable(lazy(() => import('views/application/anouncements/EditAnnouncement')));
const StudentAnouncementList = Loadable(lazy(() => import('views/application/anouncements/staffannouncement/index')));
const StudentAnouncementEdit = Loadable(lazy(() => import('views/application/anouncements/staffannouncement/StaffAnnounEdit')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const AnouncementRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/anouncements/list',
            element: <AnouncementList />
        },
        {
            path: '/anouncements/edit/:id',
            element: <AnouncementEdit />
        },
        {
            path: '/student/anouncements/list',
            element: <StudentAnouncementList />
        },
        {
            path: '/student/anouncements/edit/:id',
            element: <StudentAnouncementEdit />
        }
    ]
};
export default AnouncementRoutes;
