import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const roles = ['ROLE_STUDENT', 'ROLE_STUDENT_ADMISSION', 'ROLE_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN', 'ROLE_ADMIN'];
const InfirmaryMaster = Loadable(lazy(() => import('views/application/infirmary/Main')));
const RegisterDoctor = Loadable(lazy(() => import('views/application/infirmary/doctor/index')));
const DoctorEdit = Loadable(lazy(() => import('views/application/infirmary/doctor/Edit')));
const ODP = Loadable(lazy(() => import('views/application/infirmary/odp/index')));
const OPDDetails = Loadable(lazy(() => import('views/application/infirmary/opddetails/OPDDetilasMain')));
const InfirmaryDashboard = Loadable(lazy(() => import('views/application/infirmary/infirmarydashboard/dashboard/InfirmaryDashboard')));
const PatientProfile = Loadable(lazy(() => import('views/application/infirmary/patientprofile/PatientProfile')));
const InfirmaryRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/infirmary/master',
            element: <InfirmaryMaster />
        },
        {
            path: '/register/doctor',
            element: <RegisterDoctor />
        },
        {
            path: '/doctor/registration/edit/:id',
            element: <DoctorEdit />
        },
        {
            path: '/odp',
            element: <ODP />
        },
        {
            path: '/opd/details/:id',
            element: <OPDDetails />
        },
        {
            path: '/infirmary/dashboard',
            element: <InfirmaryDashboard />
        },
        {
            path: '/patient/profile/:id',
            element: <PatientProfile />
        }
    ]
};
export default InfirmaryRoutes;
