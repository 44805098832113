import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const EventType = Loadable(lazy(() => import('../views/application/events/event-type')));
const EventSetup = Loadable(lazy(() => import('../views/application/events/all-events')));
const EventTeams = Loadable(lazy(() => import('../views/application/events/event-teams')));
const UpdateEvent = Loadable(lazy(() => import('../views/application/events/all-events/Edit')));
const EventsToHod = Loadable(lazy(() => import('../views/application/events/hod-events')));
const EventsToAdmin = Loadable(lazy(() => import('../views/application/events/all-events/ViewParticipation')));
const EventList = Loadable(lazy(() => import('views/application/eventmodule/events/index')));
const EventTeamList = Loadable(lazy(() => import('views/application/eventmodule/eventteam/index')));
const roles = ['ROLE_STUDENT', 'ROLE_STUDENT_ADMISSION', 'ROLE_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN', 'ROLE_ADMIN'];

const EventRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/event/type',
            element: <EventType />
        },
        // {
        //     path: '/event/list',
        //     element: <EventList />
        // },
        {
            path: '/events',
            element: <EventSetup />
        },
        {
            path: '/update/event/:id',
            element: <UpdateEvent />
        },
        {
            path: '/event/teams',
            element: <EventTeams />
        },
        {
            path: '/hod/events',
            element: <EventsToHod />
        },
        {
            path: '/event/participation/view',
            element: <EventsToAdmin />
        }
        // ,
        // {
        //     path: '/event/team/list',
        //     element: <EventTeamList />
        // }
    ]
};
export default EventRoutes;
