/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
import { FormattedMessage } from 'react-intl';

// assets
import { IconApps, IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc } from '@tabler/icons';

import axios from 'axios';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc
};

const LMSRedirectionSetup = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const path = location.pathname;

    useEffect(() => {
        if (role === 'ROLE_ADMIN' && path.startsWith('/lms')) {
            adminLms(navigate, path);
        } else if (role === 'ROLE_COLLEGE_SUB_ADMIN' && path.startsWith('/lms')) {
            adminLms(navigate, path);
        } else if (role === 'ROLE_STUDENT' && path.startsWith('/lms')) {
            studentLms(path);
        }
        if (role === 'ROLE_STUDENT' && location.pathname === '/student/dashboard') {
            StudentMenu();
        }
        if (role === 'ROLE_STUDENT' && location.pathname === '/lms') {
            navigate(-1);
        }
    }, [location.pathname, role, navigate]);
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const menus = {
    id: 'application',
    icon: icons.IconApps,
    type: 'group',
    children: []
};

const menuInit = async () => {
    const currentPathName = window.location.pathname;
    if (currentPathName.startsWith('/lms')) return;
    const response = await axios.get(`https://api.entraruniversity.com/api/v1/menus/user/menu`, {
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
            'Content-Type': 'application/json'
        }
    });
    const menuData = [];
    // eslint-disable-next-line array-callback-return
    response.data.data.map(async (item, key) => {
        const temp = {
            id: item.menuName,
            title: item?.menuName,
            type: item.submenu.length === 0 ? 'mainMenu' : 'collapse',
            target: false,
            breadcrumbs: false,
            icon: item.menuIcon,
            url:
                item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'College_Admin'
                    ? '/institute/dashboard'
                    : item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'Institute_Admin'
                    ? '/institute/dashboard'
                    : item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'Faculty'
                    ? '/teacher/dashboard'
                    : item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'COACH'
                    ? '/coach/dashboard'
                    : item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'PLACEMENT_IN_CHARGE'
                    ? '/placement/dashboard'
                    : item.menuName === 'Dashboard' && localStorage.getItem('userTypes') === 'DOCTOR'
                    ? '/infirmary/dashboard'
                    : item.url,

            children: []
        };
        const submenuTemp = [];
        if (item.submenu.length > 0) {
            item.submenu.map(async (value) => {
                const tempsubmenu = {
                    id: value.menuName,
                    title: value.menuName,
                    type: 'item',
                    target: false,
                    url: value.url,
                    parent: item
                };
                submenuTemp.push(tempsubmenu);
            });
            temp.children = submenuTemp;
        }
        menuData.push(temp);
    });
    menus.children = menuData;
};
const staticMenu = () => {
    const staticMenuData = [
        {
            id: 'Institute',
            title: <FormattedMessage id="Institute" />,
            type: 'item',
            icon: icons.IconUserCheck,
            url: '/institute/list',
            children: []
        },
        {
            id: 'Institute',
            title: <FormattedMessage id="Dashboard Module" />,
            type: 'item',
            icon: icons.IconUserCheck,
            url: '/dashboard/module',
            children: []
        },
        {
            id: 'Menu',
            title: <FormattedMessage id="Menu" />,
            type: 'item',
            url: '/institute/menus/list',
            children: []
        }
    ];
    menus.children = staticMenuData;
};
const staticMenuStudent = () => {
    const staticMenuData = [
        {
            id: 'Registration',
            title: <FormattedMessage id="Registration" />,
            type: 'item',
            breadcrumbs: false,
            url: '/student/registration',
            children: []
        },
        {
            id: 'Track your Application',
            title: <FormattedMessage id="Track Your Application" />,
            type: 'item',
            breadcrumbs: false,
            url: '/student/track-your-application',
            children: []
        }
    ];
    menus.children = staticMenuData;
};
const CompanyMenu = () => {
    const staticMenuData = [
        {
            id: 'DashBoard',
            title: <FormattedMessage id="DashBoard" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/company/dashboard',
            children: []
        },
        // {
        //     id: 'Add Skills',
        //     title: <FormattedMessage id="Add Skills" />,
        //     type: 'item',
        //     // icon: icons.IconUserCheck,
        //     breadcrumbs: false,
        //     url: '/skills/list',
        //     children: []
        // },
        {
            id: 'Company Details',
            title: <FormattedMessage id="Company Details" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/company/details',
            children: []
        },
        // {
        //     id: 'Placemnet Week',
        //     title: <FormattedMessage id="Placemnet Week" />,
        //     type: 'item',
        //     // icon: icons.IconUserCheck,
        //     breadcrumbs: false,
        //     url: '/placementweeks',
        //     children: []
        // },
        {
            id: 'Create Job',
            title: <FormattedMessage id="Create Job" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/create/jobs',
            children: []
        },
        {
            id: 'Job Tracker',
            title: <FormattedMessage id="Job Tracker" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/company/job/tracker',
            children: []
        },
        {
            id: 'Hired Cadidates',
            title: <FormattedMessage id="Hired Cadidates" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/hired/candidates/list',
            children: []
        }
    ];
    menus.children = staticMenuData;
};
const StudentMenu = () => {
    const staticMenuData = [
        {
            id: 'Dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/student/dashboard',
            children: []
        },
        {
            id: 'Elective',
            title: <FormattedMessage id="Elective Selection" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/subject/electives',
            children: []
        },
        {
            id: 'Assignment',
            title: <FormattedMessage id="Assignments" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/student/assignment',
            children: []
        },
        {
            id: 'Exams',
            title: <FormattedMessage id="Exams" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/student/Exam',
            children: []
        },
        {
            id: 'QuestionBank',
            title: <FormattedMessage id="Question Bank" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/student/previous-year-questions',
            children: []
        },
        {
            id: 'Events',
            title: <FormattedMessage id="Events" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/student/events',
            children: []
        },
        {
            id: 'Exam Performance',
            title: <FormattedMessage id="Exam Performance" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/exams/performance',
            children: []
        },
        {
            id: 'Gallery',
            title: <FormattedMessage id="Gallery" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/gallery-view',
            children: []
        }
    ];
    menus.children = staticMenuData;
};

const CanteenMenu = () => {
    const staticMenuData = [
        {
            id: 'Dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/canteen/dashboard',
            children: []
        },
        {
            id: 'Setup',
            title: <FormattedMessage id="Setup" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/canteen/setup',
            children: []
        },
        {
            id: 'Orders',
            title: <FormattedMessage id="Orders" />,
            type: 'item',
            // icon: icons.IconUserCheck,
            breadcrumbs: false,
            url: '/canteen/orders',
            children: []
        }
    ];
    menus.children = staticMenuData;
};
const adminLms = (navigate, path) => {
    if (!path.startsWith('/lms') || path === '/lms') {
        navigate(`/lms/dashboard/admin`);
    }

    const staticMenuData = [
        {
            id: 'DashBoard',
            title: <FormattedMessage id="DashBoard" />,
            type: 'item',
            breadcrumbs: false,
            url: '/lms/dashboard/admin',
            children: []
        },
        {
            id: 'Curriculum',
            title: <FormattedMessage id="Curriculum" />,
            type: 'collapse',
            breadcrumbs: false,
            url: '/',
            children: [
                {
                    id: 'Lesson Plan',
                    title: <FormattedMessage id="Lesson Plan" />,
                    type: 'item',
                    breadcrumbs: false,
                    url: '/lms/lesson-plan',
                    children: []
                },
                {
                    id: 'Lesson Plan Tracker',
                    title: <FormattedMessage id="Lesson Plan Tracker" />,
                    type: 'item',
                    breadcrumbs: false,
                    url: '/lms/lesson/plan/tracking',
                    children: []
                }
            ]
        },
        {
            id: 'Assignments',
            title: <FormattedMessage id="Assignments" />,
            type: 'collapse',
            breadcrumbs: false,
            url: '/',
            children: [
                {
                    id: 'Assignments',
                    title: <FormattedMessage id="Assignments" />,
                    type: 'item',
                    breadcrumbs: false,
                    url: '/lms/add/assignment',
                    children: []
                },
                {
                    id: 'Evaluate',
                    title: <FormattedMessage id="Evaluate" />,
                    type: 'item',
                    breadcrumbs: false,
                    url: '/lms/assignment/evaluate',
                    children: []
                }
            ]
        },
        {
            id: 'Exam',
            title: <FormattedMessage id="Exam" />,
            type: 'collapse',
            breadcrumbs: false,
            url: '/',
            children: [
                {
                    id: 'Online Exam',
                    title: <FormattedMessage id="Online Exam" />,
                    type: 'item',
                    breadcrumbs: false,
                    url: '/lms/exam',
                    children: []
                },
                {
                    id: 'Screenshot',
                    title: <FormattedMessage id="Screenshot" />,
                    type: 'item',
                    breadcrumbs: false,
                    url: '/lms/exam/screen-shots',
                    children: []
                }
            ]
        }
    ];
    menus.children = staticMenuData;
};
const studentLms = (path) => {
    if (!path.startsWith('/lms') || path === '/lms') {
        window.open('/lms/dashboard/student', '_blank');
    }
    const staticMenuData = [
        {
            id: 'DashBoard',
            title: <FormattedMessage id="DashBoard" />,
            type: 'item',
            breadcrumbs: false,
            url: '/lms/dashboard/student',
            children: []
        },
        {
            id: 'Curriculum',
            title: <FormattedMessage id="Curriculum" />,
            type: 'item',
            breadcrumbs: false,
            url: '/lms/curriculum/student',
            children: []
        },
        {
            id: 'Assignment',
            title: <FormattedMessage id="Assignments" />,
            type: 'item',
            breadcrumbs: false,
            url: '/lms/assignment/student',
            children: []
        },
        {
            id: 'Exam',
            title: <FormattedMessage id="Exam" />,
            type: 'item',
            breadcrumbs: false,
            url: '/lms/assessment/student',
            children: []
        }
    ];
    menus.children = staticMenuData;
};
const role = localStorage.getItem('role');
const userType = localStorage.getItem('userTypes');
if (role === 'ROLE_SUPER_ADMIN') {
    staticMenu();
} else if (role === 'ROLE_STUDENT') {
    StudentMenu();
} else if (role === 'ROLE_STUDENT_ADMISSION') {
    staticMenuStudent();
} else if (role === 'ROLE_HIRER') {
    CompanyMenu();
} else if (role === 'ROLE_COLLEGE_ADMIN' && userType === 'Institute_Admin') {
    menuInit();
} else if (role === 'ROLE_ADMIN') {
    menuInit();
} else if (role === 'ROLE_COLLEGE_SUB_ADMIN') {
    menuInit();
} else if (role === 'ROLE_COLLEGE_ADMIN' && userType === 'Dean') {
    menuInit();
} else if (role === 'ROLE_COLLEGE_ADMIN') {
    menuInit();
} else if (role === 'ROLE_RESTAURANT_OWNER') {
    CanteenMenu();
} else {
    menuInit();
}

export { menus, LMSRedirectionSetup };
