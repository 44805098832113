import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const InventoryManagement = Loadable(lazy(() => import('views/application/inventory-management')));
const OpeningStocks = Loadable(lazy(() => import('views/application/inventory-management/opening-stocks')));
const Stocks = Loadable(lazy(() => import('views/application/inventory-management/stocks')));
const StockDetails = Loadable(lazy(() => import('views/application/inventory-management/stocks/StockDetails')));
const IndentEntry = Loadable(lazy(() => import('views/application/Indent/indent-entry')));
const IndentDetails = Loadable(lazy(() => import('views/application/Indent/indent-entry/IndentDetails')));
const Edit = Loadable(lazy(() => import('views/application/Indent/indent-entry/Edit')));
const IndentQuotation = Loadable(lazy(() => import('views/application/Indent/quotation/IndentQuotation')));
const Details = Loadable(lazy(() => import('views/application/Indent/quotation/Details')));
const EditQuotation = Loadable(lazy(() => import('views/application/Indent/quotation/EditQuotation')));
const IndentApproval = Loadable(lazy(() => import('views/application/Indent/indent-approval')));
const ApproveEdit = Loadable(lazy(() => import('views/application/Indent/indent-approval/Edit')));
const CompareQuotation = Loadable(lazy(() => import('views/application/Indent/quotation/CompareQuotation')));
const PurchaseOrder = Loadable(lazy(() => import('views/application/Indent/inward-quotation')));
const MaterialInwards = Loadable(lazy(() => import('views/application/Indent/inward-quotation/MaterialInwards')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const Inventory = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/inventory-setup',
            element: <InventoryManagement />
        },
        {
            path: '/opening-stocks',
            element: <OpeningStocks />
        },
        {
            path: '/stocks',
            element: <Stocks />
        },
        {
            path: '/stock-detail',
            element: <StockDetails />
        },
        {
            path: '/indent-entry',
            element: <IndentEntry />
        },
        {
            path: '/indent-detail',
            element: <IndentDetails />
        },
        {
            path: '/edit-indent',
            element: <Edit />
        },
        {
            path: '/indent-quotation',
            element: <IndentQuotation />
        },

        {
            path: '/indent-approval',
            element: <IndentApproval />
        },
        {
            path: '/edit-indent-approval',
            element: <ApproveEdit />
        },
        {
            path: '/quotation-detail',
            element: <Details />
        },
        {
            path: '/edit-indent-quotation',
            element: <EditQuotation />
        },
        {
            path: '/compare-quotation',
            element: <CompareQuotation />
        },
        {
            path: '/purchase-order-quotation',
            element: <PurchaseOrder />
        },
        {
            path: '/material-inwards',
            element: <MaterialInwards />
        }
    ]
};
export default Inventory;
