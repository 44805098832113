import { memo, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Stack, useMediaQuery, Typography } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports

import MenuList from '../MenuList';
import LogoSection from '../LogoSection';
import MiniDrawerStyled from './MiniDrawerStyled';
import Chip from 'ui-component/extended/Chip';

import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import './Menu.css';
// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const { layout, drawerType } = useConfig();

    const logo = useMemo(
        () => (
            <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                <LogoSection />
            </Box>
        ),
        []
    );

    const drawer = useMemo(
        () => (
            <>
                {/* <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        // paddingLeft: drawerOpen ? '16px' : 0,
                        paddingRight: drawerOpen ? '16px' : 0,
                        marginTop: drawerOpen ? 0 : '20px',
                        // backgroundColor: '#005E9F',
                        color: '#FFFFFF'
                    }}
                > */}
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        marginTop: drawerOpen ? '40px' : '0px',
                        paddingTop: matchUpMd ? '60px' : '0' // Apply padding only in desktop view
                        // ...
                    }}
                >
                    <MenuList lastItem={null} />
                    {/* {layout === LAYOUT_CONST.VERTICAL_LAYOUT && drawerOpen && <MenuCard />} */}
                    {layout === LAYOUT_CONST.VERTICAL_LAYOUT && drawerOpen && (
                        <>
                            <Stack
                                style={{
                                    color: 'white'
                                }}
                                direction="row"
                                justifyContent="center"
                                sx={{ mb: 2 }}
                                overflow="hidden"
                            >
                                <Chip
                                    label={process.env.REACT_APP_VERSION}
                                    disabled
                                    // chipcolor="secondary"
                                    size="small"
                                    sx={{ cursor: 'pointer' }}
                                />
                            </Stack>
                            <Box
                                className="power"
                                xs={24}
                                lg={24}
                                md={24}
                                sm={24}
                                sx={{
                                    position: 'fixed',
                                    justifyContent: 'space-evenly',

                                    bottom: 6,
                                    height: '4%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '1%',
                                    zIndex: '99999'
                                }}
                                style={{
                                    width: '290px',
                                    backgroundColor: 'white',
                                    color: 'black'
                                }}
                            >
                                <Typography sx={{ textAlign: 'center', mt: 5, mb: 2 }}>
                                    <img src="/assets/images/icons/Powered_by_new_entrar.svg" alt="ustm" height="40px" width="40%" />
                                </Typography>
                            </Box>
                        </>
                    )}
                </PerfectScrollbar>
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd, drawerOpen, drawerType]
    );

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            {matchDownMd || (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
                <Drawer
                    variant={matchUpMd ? 'persistent' : 'temporary'}
                    anchor="left"
                    open={drawerOpen}
                    onClose={() => dispatch(openDrawer(!drawerOpen))}
                    sx={{
                        '& .MuiDrawer-paper': {
                            mt: matchDownMd ? 0 : 11,
                            zIndex: 1099,
                            width: drawerWidth,
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none'
                        }
                    }}
                    ModalProps={{ keepMounted: true }}
                >
                    {matchDownMd && logo} {/* Conditionally render LogoSection for mobile view */}
                    {drawer}
                </Drawer>
            ) : (
                <MiniDrawerStyled variant="permanent" open={drawerOpen}>
                    {matchDownMd && logo} {/* Conditionally render LogoSection for mobile view */}
                    {drawer}
                </MiniDrawerStyled>
            )}
        </Box>
    );
};

export default memo(Sidebar);
