/* eslint-disable consistent-return */
/* eslint-disable default-param-last */
/* eslint-disable default-case */
const initialState = {
    loading: false,
    data: [],
    error: ''
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_DATA_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'FETCH_DATA_SUCCESS':
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        case 'FETCH_DATA_FAILURE':
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default dataReducer;
