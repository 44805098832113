import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MobileMenu from 'views/application/institute/mobile';
import LessonPlanResources from 'views/application/lessionPlan/lesson-plan-resources';
import TeachingResources from 'views/application/lessionPlan/view-teaching-resources';

// Department
// const MenuAccess = Loadable(lazy(() => import('views/application/menu/Menus')));
const MenuAccessAdd = Loadable(lazy(() => import('views/application/menu/index')));
const MenuAccessEdit = Loadable(lazy(() => import('views/application/menu/MenuAccessEdit')));
const MenuAdd = Loadable(lazy(() => import('views/application/menus/index')));
const SubMenuAdd = Loadable(lazy(() => import('views/application/menus/MenusAdd')));
const SubMenuEdit = Loadable(lazy(() => import('views/application/menus/SubMenuEdit')));
const GroupAdd = Loadable(lazy(() => import('views/application/menus/SubMenuEdit')));
const LessionPlan = Loadable(lazy(() => import('views/application/lessionPlan')));
const AddCurriculum = Loadable(lazy(() => import('views/application/lessionPlan/Add-lesson-plan-v2/GetLessonPlanData')));
const LessionPlanPdf = Loadable(lazy(() => import('views/application/lessionPlan/downloadpdf/index')));
const LessionPlanEdit = Loadable(lazy(() => import('views/application/lessionPlan/Add-lesson-plan-v2/EditCurriculumLayers')));
const LessionPlanView = Loadable(lazy(() => import('views/application/lessionPlan/LessonPlanView')));
const LessonPlanActivities = Loadable(lazy(() => import('views/application/lessionPlan/LessonPlanActivities')));
const ShareLessonPlan = Loadable(lazy(() => import('views/application/lessionPlan/ShareLessonPlan')));
const LessionPlanShow = Loadable(lazy(() => import('views/application/lessionPlan/LessonPlanShow')));
const SharedLessionPlan = Loadable(lazy(() => import('views/application/lessionPlan/SharedLessonPlan')));
const SharedLessionPlanWithFaculty = Loadable(lazy(() => import('views/application/lessionPlan/SharedLessonPlanWithFaculty')));
const LessonPlanTracker = Loadable(lazy(() => import('views/application/lessionPlan/lession-plan-tracker/index')));
// const CurriculumTracker = Loadable(lazy(() => import('views/application/lessionPlan/lession-plan-tracker/CurriculumTracker')));
const MenuSettings = Loadable(lazy(() => import('views/application/institute/menusettings/Add')));
const LessonPlanTracking = Loadable(lazy(() => import('views/application/lessionPlan/LessonPlanTracking')));
const SubjectResources = Loadable(lazy(() => import('views/application/lessionPlan/subject-resources')));
const CurriculumPdf = Loadable(lazy(() => import('../views/application/lessionPlan/CurriculumPdf')));

const roles = ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN', 'ROLE_COLLEGE_ADMIN'];

const InstituteRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // {
        //     path: '/institute/menus/access',
        //     element: <MenuAccess />
        // },
        {
            path: '/institute/menus/access/add',
            element: <MenuAccessAdd />
        },
        {
            path: '/institute/menus/access/edit/:id',
            element: <MenuAccessEdit />
        },
        {
            path: '/institute/menus/list',
            element: <MenuAdd />
        },
        {
            path: '/institute/submenu/add',
            element: <SubMenuAdd />
        },
        {
            path: '/institute/submenu/edit',
            element: <SubMenuEdit />
        },
        {
            path: '/institute/group/add',
            element: <GroupAdd />
        },
        {
            path: '/lesson-plan',
            // path: '/institute/lessionplan/add',
            element: <LessionPlan />
        },
        {
            path: '/add/curriculum/:id',
            element: <AddCurriculum />
        },
        {
            path: '/institute/lessionplan/pdf',
            element: <LessionPlanPdf />
        },
        {
            path: '/lesson-plan/edit/:id',
            element: <LessionPlanEdit />
        },
        {
            path: '/lesson-plan/view/:id/:subjectId/:sessionId',
            element: <LessionPlanView />
        },
        {
            path: '/share/lessionplan/:id',
            element: <ShareLessonPlan />
        },
        {
            path: '/lesson/plan/activities/:id',
            element: <LessonPlanActivities />
        },
        {
            path: '/institute/lessionplan/:id',
            element: <LessionPlanShow />
        },
        {
            path: '/shared/lessionplan/:id',
            element: <SharedLessionPlan />
        },
        {
            path: '/shared/lessionplan/with/faculty/:id/:userId',
            element: <SharedLessionPlanWithFaculty />
        },
        {
            path: '/lession/plan/tracker/:id',
            element: <LessonPlanTracker />
        },
        {
            path: '/mobile/menu/:instituteId',
            element: <MobileMenu />
        },
        {
            path: '/menu/setting/:instituteId',
            element: <MenuSettings />
        },
        {
            path: '/lesson/plan/tracker/resources',
            element: <LessonPlanResources />
        },
        {
            path: '/lesson/plan/view/teaching/resources',
            element: <TeachingResources />
        },
        {
            path: '/lesson/plan/tracking',
            element: <LessonPlanTracking />
        },
        {
            path: '/subject/resources',
            element: <SubjectResources />
        },
        {
            path: '/lesson-plan/pdf',
            element: <CurriculumPdf />
        }
    ]
};

export default InstituteRoutes;
