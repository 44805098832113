import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const ExamSetup = Loadable(lazy(() => import('views/student/online-exam')));
const Instructions = Loadable(lazy(() => import('views/student/online-exam/instructions')));
const SystemCheck = Loadable(lazy(() => import('views/student/online-exam/SystemCheck')));
const ValidatePage = Loadable(lazy(() => import('views/student/online-exam/Validate')));
const ExamDetails = Loadable(lazy(() => import('views/student/online-exam/ExamDetails')));
// const ExamDetails = Loadable(lazy(() => import('views/student/online-exam/ExamDetails')));
const RegistrationDetail = Loadable(lazy(() => import('views/admissionregister/index')));
const BasicTab = Loadable(lazy(() => import('views/admissionregister/BasicTab')));
const TrackYourApplication = Loadable(lazy(() => import('views/student/application-tracker')));
const ApplyScholarship = Loadable(lazy(() => import('views/student/apply-scholarship/index')));
const ScholarshipDocuments = Loadable(lazy(() => import('views/student/apply-scholarship/Documents')));
const EntranceExamDetails = Loadable(lazy(() => import('views/admissionregister/entrance-exam')));
const InstructionsForEntrance = Loadable(lazy(() => import('views/admissionregister/entrance-exam/Instructions')));
const StartEntranceExam = Loadable(lazy(() => import('views/admissionregister/entrance-exam/StartEntranceExam')));
const StudentEdit = Loadable(lazy(() => import('views/admissionregister/Edit')));
const StudentDetails = Loadable(lazy(() => import('views/admissionregister/details/index')));
const AdmissionFeePayment = Loadable(lazy(() => import('views/studentadmissionProfile/AdmissionFeePayment')));
// const StudentAddmission = Loadable(lazy(() => import('views/addmissionform/index')));
const BasicInfoTab = Loadable(lazy(() => import('views/addmissionform/BasicInfoTab')));
const StudentAddmissionList = Loadable(lazy(() => import('views/StudentAdmissionList/Index')));
// eslint-disable-next-line import/no-unresolved
const StudentAddmissionProfile = Loadable(lazy(() => import('views/StudentAdmissionList/StudentAdmissionProfile')));
const StudentAddmissionFinal = Loadable(lazy(() => import('views/student/admissionstudentList/index')));
const StudentDashBoard = Loadable(lazy(() => import('views/student/student-dashboard/Index')));
const LibraryDashboard = Loadable(lazy(() => import('views/Library/dashboards/student')));
const NonRatedBooks = Loadable(lazy(() => import('views/Library/dashboards/student/NonRatedBooks')));
const StudentAlumni = Loadable(lazy(() => import('views/alumni/AlumniList')));
const OfflineRegistration = Loadable(lazy(() => import('views/offlineregistration/index')));
const BasicInfoTabOfline = Loadable(lazy(() => import('views/offlineadmission/BasicInfoTab')));
const InternalAdmissionForm = Loadable(lazy(() => import('views/offlineinternaladmission/InternalAdmissionForm')));
const InternalAdmissionProfile = Loadable(lazy(() => import('views/offlineinternaladmission/AdmissionProfile')));
const OfflineStudentProfile = Loadable(lazy(() => import('views/offlineadmission/Profile')));
const InternalAdmission = Loadable(lazy(() => import('views/offlineadmission/InternalAdmission')));
const SdtudentPromotion = Loadable(lazy(() => import('views/application/student-permotion/index')));
const SdtudentAttendance = Loadable(lazy(() => import('views/application/StudentAttendance/Attenance')));
const SdtudentAttendanceprofile = Loadable(lazy(() => import('views/application/StudentAttendance/Attenance')));
const AssignmentList = Loadable(lazy(() => import('views/student/student-details/dashboard/profile/assignment/AssignmentList')));
const Assignment = Loadable(lazy(() => import('views/student/student-details/dashboard/profile/assignment/Assignment')));

const UpdateStudentAttendance = Loadable(lazy(() => import('views/application/StudentAttendance/UpadateStudentAttendance')));

const StudentGroupList = Loadable(lazy(() => import('views/application/studentgroup/index')));
const StudentGroupListEdit = Loadable(lazy(() => import('views/application/studentgroup/EditGroup')));
const BasicAssignmentTabs = Loadable(lazy(() => import('views/student/assignment')));
const AssignmentDetail = Loadable(lazy(() => import('views/student/assignment/AssignmentDetail')));
const StartAssignment = Loadable(lazy(() => import('views/student/assignment/StartAssignment')));
const AnswerSheet = Loadable(lazy(() => import('views/student/assignment/AnswerSheet')));
const FinalResult = Loadable(lazy(() => import('views/student/assignment/FinalResult')));
const StudentSummary = Loadable(lazy(() => import('views/application/studentsummary/StudentSummary')));
const Events = Loadable(lazy(() => import('../views/student/events')));
const StudentDocuments = Loadable(lazy(() => import('../views/student/documents')));
const ExamMarksheets = Loadable(lazy(() => import('../views/student/marksheets')));
const ExamPerformance = Loadable(lazy(() => import('../views/student/exam-performance')));
const StudentCanteen = Loadable(lazy(() => import('../views/student/canteen')));

const QuestionBank = Loadable(lazy(() => import('../views/questionBank/previous-year-paper/PreviouseYearPaper')));

const StartExam = Loadable(lazy(() => import('../views/student/online-exam/StartExam')));
const Gallery = Loadable(lazy(() => import('../views/student/gallery-view')));

const roles = [
    'ROLE_STUDENT',
    'ROLE_STUDENT_ADMISSION',
    'ROLE_COLLEGE_ADMIN',
    'ROLE_ADMIN',
    'ROLE_COLLEGE_SUB_ADMIN',
    'ROLE_ADMIN',
    'ROLE_HIRER'
];

const StudentRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/student/registration',
            element: <BasicTab />
        },
        {
            path: '/student/previous-year-questions',
            element: <QuestionBank />
        },
        {
            path: '/student/track-your-application',
            element: <TrackYourApplication />
        },
        {
            path: '/student/track-your-application/:id',
            element: <TrackYourApplication />
        },
        {
            path: '/student/apply-scholarship',
            element: <ApplyScholarship />
        },
        {
            path: '/view-documents/:id',
            element: <ScholarshipDocuments />
        },
        {
            path: '/entrance-exam',
            element: <EntranceExamDetails />
        },
        {
            path: '/student/start-exam/entrance',
            element: <StartEntranceExam />
        },
        {
            path: '/entrance-exam/instructions',
            element: <InstructionsForEntrance />
        },
        {
            path: '/student/registration/detail',
            element: <RegistrationDetail />
        },
        {
            path: '/student/edit',
            element: <StudentEdit />
        },
        {
            path: '/student/details',
            element: <StudentDetails />
        },
        {
            path: '/student/addmission/:id',
            element: <BasicInfoTab />
        },
        {
            path: '/student/addmission/List',
            element: <StudentAddmissionList />
        },
        {
            path: '/student/addmission/profile/:id/:courseDetailId',
            element: <StudentAddmissionProfile />
        },
        {
            path: '/student/addmission/final/list',
            element: <StudentAddmissionFinal />
        },
        {
            path: '/student/dashboard',
            element: <StudentDashBoard />
        },
        {
            path: '/student/library/dashboard',
            element: <LibraryDashboard />
        },
        {
            path: '/books/add-reviews',
            element: <NonRatedBooks />
        },
        // {
        //     path: '/student/library/books',
        //     element: <StudentBooks />
        // },
        {
            path: '/student/dashboard/studentattendanceReport',
            element: <StudentDashBoard />
        },
        {
            path: '/offline/registration',
            element: <OfflineRegistration />
        },
        {
            path: '/offline/student/profile',
            element: <OfflineStudentProfile />
        },
        {
            path: '/internal/admission',
            element: <InternalAdmission />
        },
        {
            path: '/offline/admission/:cId/:uId',
            element: <BasicInfoTabOfline />
        },
        {
            path: '/internal/admission/:cId/:uId',
            element: <InternalAdmissionForm />
        },
        {
            path: '/internal/admission/profile/:cId/:uId',
            element: <InternalAdmissionProfile />
        },
        {
            path: '/student/promotion',
            element: <SdtudentPromotion />
        },
        {
            path: '/student/attendances/:id',
            element: <SdtudentAttendance />
        },
        {
            path: '/student/attendances',
            element: <SdtudentAttendance />
        },
        {
            path: '/student/dashboard/allassignments',
            element: <AssignmentList />
        },
        {
            path: '/student/dashboard/assignment/:id',
            element: <Assignment />
        },
        {
            path: '/student/alumni',
            element: <StudentAlumni />
        },
        {
            path: '/student/group/list',
            element: <StudentGroupList />
        },
        {
            path: '/student/group/edit/:id',
            element: <StudentGroupListEdit />
        },
        {
            path: '/student/assignment',
            element: <BasicAssignmentTabs />
        },
        {
            path: '/student/assignment-details/:id/:studentNamee/:studentIds',
            element: <AssignmentDetail />
        },
        {
            path: '/student/start-assignment/:id/:answerSheetId',
            element: <StartAssignment />
        },
        {
            path: '/student/result',
            element: <FinalResult />
        },
        {
            path: '/student/answer-sheet',
            element: <AnswerSheet />
        },
        {
            path: '/student/Exam',
            element: <ExamSetup />
        },
        {
            path: '/systemcheck',
            element: <SystemCheck />
        },
        {
            path: '/validate',
            element: <ValidatePage />
        },
        {
            path: '/instructions',
            element: <Instructions />
        },
        {
            path: '/student/exam-details/:id/:studentNamee/:studentIds',
            element: <ExamDetails />
        },
        {
            path: '/student/start-exam/:id/:answerSheetId',
            element: <StartExam />
        },
        {
            path: '/payment/admission-fee',
            element: <AdmissionFeePayment />
        },
        {
            path: '/student/summary/:id',
            element: <StudentSummary />
        },
        {
            path: '/update/student/attendance',
            element: <UpdateStudentAttendance />
        },
        {
            path: '/student/events',
            element: <Events />
        },
        {
            path: '/documents/student',
            element: <StudentDocuments />
        },
        {
            path: '/exams/marksheets/view',
            element: <ExamMarksheets />
        },
        {
            path: '/exams/performance',
            element: <ExamPerformance />
        },
        {
            path: '/canteen/place-order',
            element: <StudentCanteen />
        },
        {
            path: '/gallery-view',
            element: <Gallery />
        }
    ]
};
export default StudentRoutes;
