/* eslint-disable jsx-a11y/alt-text */
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Link, useMediaQuery } from '@mui/material';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import LocalizationSection from './LocalizationSection';
import MegaMenuSection from './MegaMenuSection';
import NotificationSection from './NotificationSection';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();
    const [instituteLogo, setInstituteLogo] = useState('');
    const { instituteId } = useParams();
    const role = localStorage.getItem('role');

    const downloadDocumentFunction = (fileName) => {
        axios
            .post(
                `/v1/utils/file/download`,
                { fileName },
                {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((result) => {
                setInstituteLogo(result.data.data);
            });
    };

    const getInstituteHeader = () => {
        if (role === 'ROLE_STUDENT_ADMISSION') return;
        if (role === 'ROLE_RESTAURANT_OWNER') return;
        axios
            .get(`/v1/institutes/get?instituteId=${instituteId}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            })
            .then((result) => {
                if (result?.data?.data?.header !== '') {
                    downloadDocumentFunction(result?.data?.data?.header);
                }
            });
    };

    useEffect(() => {
        if (role !== 'ROLE_HIRER') {
            getInstituteHeader();
        }
    }, []);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 200,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
                // style={{
                //     backgroundColor: 'red',
                //     borderRadius: '4.5%',
                //     paddingRight: '30px'
                // }}
            >
                {/* <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <img src="/assets/images/icons/ustm-logo_T.svg" width="200px" alt="" />
                </Box> */}
            </Box>
            {layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
                <>
                    <Avatar
                        variant="rounded"
                        style={{ backgroundColor: '#F2A516' }}
                        sx={{
                            // ...theme.typography.commonAvatar,
                            // ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            ml: 1,
                            transition: 'all .2s ease-in-out'
                            // background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            // color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            // '&:hover': {
                            //     background: theme.palette.mode === '#005E9F' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            //     color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                            // }
                        }}
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                    >
                        <IconMenu2
                            stroke={1.2}
                            style={{
                                // color:"#FFCD00",
                                color: 'black'
                            }}
                            size="20px"
                        />
                    </Avatar>
                    <Box sx={{ flexGrow: 0.1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        {/* <img src={role === 'ROLE_SUPER_ADMIN' ? '/asset/entrar@university.svg' : instituteLogo} /> */}
                        <img style={{ width: '300px', marginTop: '12px' }} src="/assets/images/icons/entrar@university.svg" />
                    </Box>
                </>
            ) : null}

            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* mega-menu */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <MegaMenuSection />
            </Box> */}

            {/* live customization & localization */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}

            {/* notification & profile */}
            <NotificationSection />
            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
